/*
Copyright 2019 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

@import '../commons/index.css';
@import 'font.css';

/*@mixin typography .spectrum-Body1 {}
@mixin typography .spectrum-Body2 {}
@mixin typography .spectrum-Body3 {}
@mixin typography .spectrum-Body4 {}
@mixin typography .spectrum-Body5 {}
@mixin typography .spectrum-Heading1 {}
@mixin typography .spectrum-Heading2 {}
@mixin typography .spectrum-Heading3 {}
@mixin typography .spectrum-Heading4 {}
@mixin typography .spectrum-Heading5 {}
@mixin typography .spectrum-Heading6 {}
@mixin typography .spectrum-Subheading {}
@mixin typography .spectrum-Detail {}
@mixin typography .spectrum-Heading1--quiet, heading-quiet-1 {}
@mixin typography .spectrum-Heading2--quiet, heading-quiet-2 {}
@mixin typography .spectrum-Heading1--strong, heading-strong-1 {}
@mixin typography .spectrum-Heading2--strong, heading-strong-2 {}
@mixin typography .spectrum-Heading1--display, display-1 {}
@mixin typography .spectrum-Heading2--display, display-2 {}
@mixin typography .spectrum-Heading1--display.spectrum-Heading1--strong, display-strong-1 {}
@mixin typography .spectrum-Heading2--display.spectrum-Heading2--strong, display-strong-2 {}
@mixin typography .spectrum-Heading1--display.spectrum-Heading1--quiet, display-quiet-1 {}
@mixin typography .spectrum-Heading2--display.spectrum-Heading2--quiet, display-quiet-2 {}

.spectrum-Typography {
  @mixin typographyMargins .spectrum-Body1 {}
  @mixin typographyMargins .spectrum-Body2 {}
  @mixin typographyMargins .spectrum-Body3 {}
  @mixin typographyMargins .spectrum-Body4 {}
  @mixin typographyMargins .spectrum-Body5 {}
  @mixin typographyMargins .spectrum-Heading1 {}
  @mixin typographyMargins .spectrum-Heading2 {}
  @mixin typographyMargins .spectrum-Heading3 {}
  @mixin typographyMargins .spectrum-Heading4 {}
  @mixin typographyMargins .spectrum-Heading5 {}
  @mixin typographyMargins .spectrum-Heading6 {}
  @mixin typographyMargins .spectrum-Subheading {}
  @mixin typographyMargins .spectrum-Detail {}
  @mixin typographyMargins .spectrum-Heading1--quiet, heading-quiet-1 {}
  @mixin typographyMargins .spectrum-Heading2--quiet, heading-quiet-2 {}
  @mixin typographyMargins .spectrum-Heading1--strong, heading-strong-1 {}
  @mixin typographyMargins .spectrum-Heading2--strong, heading-strong-2 {}
  @mixin typographyMargins .spectrum-Heading1--display, display-1 {}
  @mixin typographyMargins .spectrum-Heading2--display, display-2 {}
  @mixin typographyMargins .spectrum-Heading1--display.spectrum-Heading1--strong, display-strong-1 {}
  @mixin typographyMargins .spectrum-Heading2--display.spectrum-Heading2--strong, display-strong-2 {}
  @mixin typographyMargins .spectrum-Heading1--display.spectrum-Heading1--quiet, display-quiet-1 {}
  @mixin typographyMargins .spectrum-Heading2--display.spectrum-Heading2--quiet, display-quiet-2 {}
}

.spectrum-Article {
  font-family: var(--spectrum-body-article-1-text-font-family);
  @mixin typography .spectrum-Body1, body-article-1 {}
  @mixin typography .spectrum-Body2, body-article-2 {}
  @mixin typography .spectrum-Body3, body-article-3 {}
  @mixin typography .spectrum-Body4, body-article-4 {}
  @mixin typography .spectrum-Body5, body-article-5 {}
  @mixin typography .spectrum-Heading1, heading-article-1 {}
  @mixin typography .spectrum-Heading2, heading-article-2 {}
  @mixin typography .spectrum-Heading3, heading-article-3 {}
  @mixin typography .spectrum-Heading4, heading-article-4 {}
  @mixin typography .spectrum-Heading5, heading-article-5 {}
  @mixin typography .spectrum-Heading6, heading-article-6 {}
  @mixin typography .spectrum-Subheading, subheading-article {}
  @mixin typography .spectrum-Detail, detail-article {}
  @mixin typography .spectrum-Heading1--quiet, heading-quiet-article-1 {}
  @mixin typography .spectrum-Heading2--quiet, heading-quiet-article-2 {}
  @mixin typography .spectrum-Heading1--display, display-article-1 {}
  @mixin typography .spectrum-Heading2--display, display-article-2 {}
  @mixin typography .spectrum-Heading1--display.spectrum-Heading1--quiet, display-quiet-article-1 {}
  @mixin typography .spectrum-Heading2--display.spectrum-Heading2--quiet, display-quiet-article-2 {}
}
.spectrum {
  &:lang(ja),
  &:lang(ko),
  &:lang(zh) {
    @mixin typography .spectrum-Body1, body-han-1 {}
    @mixin typography .spectrum-Body2, body-han-2 {}
    @mixin typography .spectrum-Body3, body-han-3 {}
    @mixin typography .spectrum-Body4, body-han-4 {}
    @mixin typography .spectrum-Body5, body-han-5 {}
    @mixin typography .spectrum-Heading1, heading-han-1 {}
    @mixin typography .spectrum-Heading2, heading-han-2 {}
    @mixin typography .spectrum-Heading3, heading-han-3 {}
    @mixin typography .spectrum-Heading4, heading-han-4 {}
    @mixin typography .spectrum-Heading5, heading-han-5 {}
    @mixin typography .spectrum-Heading6, heading-han-6 {}
    @mixin typography .spectrum-Subheading, subheading-han {}
    @mixin typography .spectrum-Detail, detail-han {}
    @mixin typography .spectrum-Heading1--quiet, heading-quiet-han-1 {}
    @mixin typography .spectrum-Heading2--quiet, heading-quiet-han-2 {}
    @mixin typography .spectrum-Heading1--strong, heading-strong-han-1 {}
    @mixin typography .spectrum-Heading2--strong, heading-strong-han-2 {}
    @mixin typography .spectrum-Heading1--display, display-han-1 {}
    @mixin typography .spectrum-Heading2--display, display-han-2 {}
    @mixin typography .spectrum-Heading1--display.spectrum-Heading1--strong, display-strong-han-1 {}
    @mixin typography .spectrum-Heading2--display.spectrum-Heading2--strong, display-strong-han-2 {}
    @mixin typography .spectrum-Heading1--display.spectrum-Heading1--quiet, display-quiet-han-1 {}
    @mixin typography .spectrum-Heading2--display.spectrum-Heading2--quiet, display-quiet-han-2 {}
  }
}
@mixin typography .spectrum-Code1, body-code-1, true {
  font-family: var(--spectrum-body-code-1-text-font-family);
}
@mixin typography .spectrum-Code2, body-code-2, true {
  font-family: var(--spectrum-body-code-2-text-font-family);
}
@mixin typography .spectrum-Code3, body-code-3, true {
  font-family: var(--spectrum-body-code-3-text-font-family);
}
@mixin typography .spectrum-Code4, body-code-4, true {
  font-family: var(--spectrum-body-code-4-text-font-family);
}
@mixin typography .spectrum-Code5, body-code-5, true {
  font-family: var(--spectrum-body-code-5-text-font-family);
}*/

/*
  The &.spectrum makes it so users can do <div class="spectrum spectrum--large"> and still get the right font sizes
  Without this, they would have to do <div class="spectrum--large"><div class="spectrum">, which makes no sense
*/
&.spectrum,
&.spectrum-Body,
.spectrum,
.spectrum-Body {
  font-size: var(--spectrum-body-4-text-size);
  font-weight: var(--spectrum-body-4-text-font-weight);
  line-height: var(--spectrum-body-4-text-line-height);
  font-style: var(--spectrum-body-4-text-font-style);
}

.spectrum-Body--italic {
  font-style: var(--spectrum-body-4-emphasis-text-font-style);
}

/*
.spectrum-Body--large {
  @extend .spectrum-Body2;
}

.spectrum-Body--small {
  @extend .spectrum-Body5;
}

.spectrum-Body--secondary {
  @extend .spectrum-Body4;
}

.spectrum-Heading--display {
  @extend .spectrum-Heading2--display.spectrum-Heading2--quiet;
}

.spectrum-Heading--pageTitle {
  @extend .spectrum-Heading2--quiet;
}

.spectrum-Heading--subtitle1 {
  @extend .spectrum-Heading4;
}

.spectrum-Heading--subtitle2 {
  @extend .spectrum-Heading6;
}

.spectrum-Heading--subtitle3 {
  @extend .spectrum-Subheading;
}*/
